<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie os arquivos de dados
    </div>
    <v-row
      id="atualizacao-upload-files"
      class="mx-auto"
    >
      <v-col cols="12">
        <upload-files
          id="fs-upload-atualizacao-bdgd"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="uploadedFilesLength"
    >
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto mb-8 mt-4 break-search"
          label="Procurar"
          hide-details
          single-line
        />
        <v-data-table
          class="importacoes-bdgd-table"
          :headers="[
            {
              text: 'Arquivo',
              value: 'arquivo'
            },
            {
              text: 'Tipo de arquivo',
              value: 'extensao'
            }
          ]"
          :items="uploadedFiles"
          :search.sync="search"
          :headerProps="{
            sortByText: 'Ordenar Por'
          }"
          disable-pagination
          hide-default-footer
          dense
          no-data-text="Nenhum arquivo encontrado"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="800"
        >
          <template v-slot:[`item.arquivo`]="{ item }">
            {{ item.arquivo }}
          </template>
          <template v-slot:[`item.extensao`]="{ item }">
            {{ item.extensao.toLowerCase() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      id="atualizacao-encoding"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-encoding
          id="fs-encoding-atualizacao-bdgd"
          label="Codificação dos arquivos"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="atualizacao-delimitador"
      class="mx-auto"
      v-if="hasCsvFile"
    >
      <v-col cols="12">
        <v-text-field
          label="Delimitador"
          maxlength="1"
          outlined
          v-model="delimiter"
        />
      </v-col>
    </v-row>
    <v-row
      id="atualizacao-entidade"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-bdgd-entity
          :type="'outlined'"
          @entitySelected="entitySelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="isPolygonTypeEntity"
    >
      <v-col cols="12">
        <v-alert
          dense
          outlined
          type="info"
          color="blue"
        >
          <a
            href="javascript:void(0)"
            @click="downloadFileModel"
            >Clique aqui</a
          >
          para baixar o arquivo modelo de atualização de geometrias do tipo
          polígono para a entidade {{ this.entidadesTipoPoligono.join(', ') }}.
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="uploadedFilesLength"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="gerarInserts"
          label="Caso o COD_ID não exista na tabela, inserir uma nova linha (operação INSERT)"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="uploadedFilesLength"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="backupEntity"
          label="Efetuar backup da entidade antes de atualizar"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="uploadedFilesLength"
    >
      <v-col cols="12">
        <v-checkbox
          v-model="deleteData"
          label="Apagar as linhas ao invés de atualizar"
          color="error"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AtualizacoesBdgdService from '@/services/AtualizacoesBdgdService';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding: () => import('@/components/general/SelectEncoding.vue'),
    SelectBdgdEntity: () => import('@/components/general/SelectBdgdEntity.vue'),
    UploadFiles: () => import('@/components/general/UploadFiles/Index.vue')
  },
  data() {
    return {
      encoding: 'UTF-8',
      delimiter: ';',
      entity: null,
      backupEntity: false,
      deleteData: false,
      gerarInserts: true,
      allowedFiles: [],
      uploadedFiles: [],
      search: null,
      entidadesTipoPoligono: [
        'SUB'
        // As entidades abaixo, apesar de serem do tipo polígono, são muito complexas e com muitos vértices,
        // não faz sentido utilizar essa funcionalidade para atualizar as geometrias
        // 'ARAT',
        // 'CONJ'
      ]
    };
  },
  mounted() {
    this.getAllowedFiles();
  },
  watch: {
    encoding() {
      this.$emit('encoding:selected', this.encoding);
    },
    delimiter() {
      this.$emit('delimiter:updated', this.delimiter);
    },
    backupEntity() {
      this.$emit('backupEntity:changed', this.backupEntity);
    },
    deleteData() {
      this.$emit('deleteData:changed', this.deleteData);
    },
    gerarInserts() {
      this.$emit('gerarInserts:changed', this.gerarInserts);
    },
    entity() {
      this.$emit('entity:selected', this.entity);
    },
    uploadedFiles() {
      this.$emit('file:uploaded', this.uploadedFiles);
    }
  },
  computed: {
    uploadedFilesLength() {
      return this.uploadedFiles.length;
    },
    hasCsvFile() {
      const csvFile = this.uploadedFiles.find(
        (file) => file.extensao === 'csv'
      );
      return csvFile && csvFile.id > 0;
    },
    isPolygonTypeEntity() {
      return this.entidadesTipoPoligono.includes(this.entity);
    }
  },
  methods: {
    getAllowedFiles() {
      AtualizacoesBdgdService.getAllowedFiles()
        .then((response) => (this.allowedFiles = response.data))
        .catch((error) => console.log('Error: ' + error));
    },
    downloadFileModel() {
      AtualizacoesBdgdService.baixarArquivoModeloAtualizacaoEntidadeTipoPoligono()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            'modelo-atualizacao-geometria-entidade-sub.xlsx'
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo modelo.', '', {
            position: 'topRight'
          });
        });
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    encodingSelected(event) {
      this.encoding = event;
    },
    delimiterUpdated(event) {
      this.delimiter = event;
    },
    entitySelected(entity) {
      this.entity = entity;
    }
  }
};
</script>
